// ------------------------------------------------
// Helper Functions
// ------------------------------------------------

// Make foreach functions play nice with query selector all
const forEachAll = function(array, callback, scope) {
  for (var i = 0; i < array.length; i++) {
    callback.call(scope, i, array[i]); // passes back stuff we need
  }
};

const onReturn = function(element, callback) {
  element.addEventListener('keydown', function(e) {
    callback(e);
  });
};

// Event on click
const clickEvt = (element, callback) => {
  element.addEventListener('click', function(e) {
    callback(e);
  });
};

// ------------------------------------------------
// Nav Open/Close
// ------------------------------------------------
if (document.querySelector('.sn__btn')) {
  const $menubtn = document.querySelector('.sn__btn'),
    $navmenu = document.querySelector('.site-nav');
  // bind open close to sn__btn
  $menubtn.addEventListener('click', function(e) {
    e.preventDefault();

    // adding a class to the menu hamburger to
    // turn into an x
    if ($menubtn.classList.contains('js-open')) {
      $menubtn.classList.add('js-close');
      $menubtn.querySelector('.txt').innerHTML = 'Menu';
      setTimeout(function() {
        $menubtn.classList.remove('js-open');
        $menubtn.classList.remove('js-close');
      }, 400);
    } else {
      $menubtn.classList.remove('js-close');
      $menubtn.classList.add('js-open');
      $menubtn.querySelector('.txt').innerHTML = 'Close';
    }
    // End of hamburger class adding

    if ($navmenu.classList.contains('js-open')) {
      $navmenu.classList.add('js-close');

      setTimeout(function() {
        $navmenu.classList.remove('js-open');
        $navmenu.classList.remove('js-close');
      }, 400);
    } else {
      $navmenu.classList.remove('js-close');
      $navmenu.classList.add('js-open');
    }
  });

  // bind a tags to close on click and then scroll
  // close menu when clicking body

  const $navlinks = $navmenu.querySelectorAll('a');
  forEachAll($navlinks, index => {
    $navlinks[index].addEventListener('click', function() {
      $menubtn.classList.remove('js-open');
      $menubtn.classList.remove('js-close');
      $navmenu.classList.remove('js-open');
      $navmenu.classList.remove('js-close');
    });
  });
}

// ------------------------------------------------
// Inline Banner Image
// ------------------------------------------------
const inlineBanner = function(element) {
  const bimg = element.querySelector('img'),
    bimgpath = bimg.getAttribute('src');

  if (!bimg.removeNode) {
    bimg.remove();
  } else {
    bimg.removeNode();
  }
  element.style.backgroundImage = 'url(' + bimgpath + ')';
};
if (document.querySelector('.hero-banner-wrapper')) {
  inlineBanner(document.querySelector('.hero-banner-wrapper'));
}

// ------------------------------------------------
// MBP Sign In
// ------------------------------------------------
const formValidate = function(form) {
  const fields = form.querySelectorAll('input[required]');

  let errorstate = true;

  const checkfields = function() {
    errorstate = false;

    forEachAll(fields, function(index) {
      if (fields[index].value < 1) {
        fields[index].parentNode.classList.add('error');
        errorstate = true;
      } else {
        fields[index].parentNode.classList.remove('error');
      }
    });
  };

  checkfields(fields);

  return errorstate;
};

// intercepts click form submission from fake form and fires mbpPost()
const mbpSubmitClick = function(formId) {
  const form = formId,
    formSubmit = form.querySelector('.mbpSubmit');
  clickEvt(formSubmit, event => {
    event.preventDefault();
    let formError = formValidate(form);

    if (formError === false) {
      mbpPost(formId);
      return false;
    }
  });
};

// intercepts enter form submission from fake form and fires mbpPost()
const mbpSubmitEnter = function(formId) {
  const form = formId,
    formUser = form.querySelector('input[type="text"]'),
    formPass = form.querySelector('input[type="password"]');

  let postOnEnter = function() {
    if (event.keyCode === 13) {
      let formError = formValidate(form);
      event.preventDefault();

      if (!formError) {
        mbpPost(formId);
        return false;
      }
    }
  };

  onReturn(formUser, function() {
    postOnEnter();
  });

  onReturn(formPass, function() {
    postOnEnter();
  });
};

if (document.querySelector('.mbp__login')) {
  mbpSubmitEnter(document.querySelector('#mbpLogin'));
  mbpSubmitClick(document.querySelector('#mbpLogin'));
}

// Collect values from fake login
// creates new hidden form
// submits form and opens mbp
// clears fake form and values
const mbpPost = function(formId) {
  let user = formId.querySelector('input[type="text"]'),
    password = formId.querySelector('input[type="password"]'),
    userVal = user.value,
    passwordVal = password.value;

  let form =
    '<form style="display: none;" action="https://myblueprint.blueadvantagearkansas.com/login/login.aspx" method="post" target="_blank">' +
    '<input type="hidden" name="username" value="' +
    userVal +
    '" />' +
    '<input type="hidden" name="password"  value="' +
    passwordVal +
    '"/>' +
    '</form>';

  document
    .querySelector('body')
    .appendChild(document.createElement('div'))
    .setAttribute('id', 'loginForm');
  document.querySelector('#loginForm').innerHTML = form;

  document.querySelector('#loginForm form').submit();
  user.value = '';
  password.value = '';
  userVal = '';
  passwordVal = '';
  document.querySelector('#loginForm').remove();
};

// ------------------------------------------------
// jQuery Bits
// ------------------------------------------------
jQuery(document).ready(function($) {
  $('#myTabs a').click(function(e) {
    e.preventDefault();
    $(this).tab('show');
  });

  $('[data-toggle="popover"]').popover();

  $('[data-toggle="tooltip"]').tooltip({
    html: true
  });

  // =======================================================
  // Smooth Scrolling
  // =======================================================
  $('a[href*="#"]:not([data-toggle="collapse"])').click(function() {
    if (
      location.pathname.replace(/^\//, '') ==
        this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate(
          {
            scrollTop: target.offset().top
          },
          1000
        );
        return false;
      }
    }
  });
});

// ------------------------------------------------
// Add Aria Labels
// ------------------------------------------------
if (document.querySelector('section[data-placeholder-label]')) {
  const ariaSections = document.querySelectorAll(
    'section[data-placeholder-label]:not(.subbanner)'
  );

  forEachAll(ariaSections, index => {
    let el = ariaSections[index],
      sectionLabel = ariaSections[index].getAttribute('data-placeholder-label');

    el.setAttribute('aria-labelledby', sectionLabel);
  });
}

if (document.querySelector('.sidebar-secondary[data-placeholder-label]')) {
  const ariaSidebars = document.querySelectorAll(
    '.sidebar-secondary[data-placeholder-label]'
  );

  forEachAll(ariaSidebars, index => {
    let el = ariaSidebars[index],
      asideLabel = ariaSidebars[index].getAttribute('data-placeholder-label');

    el.setAttribute('aria-label', asideLabel);
  });
}

// ====---------------====
// ID Card
// ====---------------====
if (document.querySelector('.id-card-controls')) {
  const idcard = document.querySelector('.id-card-inner'),
    idbutton = document.querySelector('.id-card-controls');

  idbutton.addEventListener('click', function() {
    if (document.querySelector('.id-card-inner.front')) {
      idcard.classList.remove('front');
      idcard.classList.add('back');
    } else {
      idcard.classList.remove('back');
      idcard.classList.add('front');
    }
  });
}
